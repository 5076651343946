<template>
    <template-layout>
        <template slot="drawers">
            <order-overview
                :order="selectedOrder"
                :active.sync="showOrderOverviewDrawer"
            />
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Dashboard</span>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row v-if="isAdminOrCallcenter">
                    <v-col cols="12" sm="4">
                        <dashboard-orders-graph
                            :stats="stats"
                        />
                    </v-col>

                    <v-col cols="12" sm="4">
                        <dashboard-sales-graph
                            :stats="stats"
                        />
                    </v-col>

                    <v-col cols="4">
                        <widget-shipping-status
                            :shipments="this.shipments"
                            :donutHeight="220"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="isAdminOrCallcenter">
                    <v-col cols="12" lg="6">
                        <dashboard-recent-orders
                            v-model="selectedOrder"
                        />
                    </v-col>

                    <v-col cols="12" lg="6">
                        <dashboard-recent-comments class="mb-7" />
                    </v-col>
                </v-row>

                <v-row v-if="isAdminOrCallcenter">
                    <v-col cols="12" lg="6">
                        <dashboard-new-customers />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import OrderOverview from '@/components/Order/OrderOverview';
    import DashboardOrdersGraph from './DashboardOrdersGraph.vue';
    import DashboardSalesGraph from './DashboardSalesGraph.vue';
    import DashboardRecentOrders from './DashboardRecentOrders.vue';
    import DashboardRecentComments from './DashboardRecentComments.vue';
    import DashboardNewCustomers from './DashboardNewCustomers.vue';
    import WidgetShippingStatus from '@/components/Widgets/WidgetShippingStatus.vue';

    export default {
        components: {
            DashboardOrdersGraph,
            DashboardSalesGraph,
            DashboardRecentComments,
            DashboardNewCustomers,
            DashboardRecentOrders,
            OrderOverview,
            TemplateLayout,
            WidgetShippingStatus
        },

        data() {
            return {
                showOrderOverviewDrawer: false,
                selectedOrder: null,

                shipments: [],

                stats: []
            };
        },

        computed: {
            isAdminOrCallcenter() {
                return this.$store.getters['user/hasRole']('ROLE_ADMIN') || this.$store.getters['user/hasRole']('ROLE_CALLCENTER');
            }
        },

        watch: {
            selectedOrder(val) {
                this.showOrderOverviewDrawer = (val != null);
            }
        },

        timers: {
            refreshTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        mounted() {
            this.refreshTimer();
        },

        methods: {
            refreshTimer() {
                this.fetchOrderStats();
                this.fetchShipments();
            },

            async fetchShipments() {
                let shipments = await this.$store.dispatch('shipments/fetchAll', {
                    date: this.$moment().format('YYYY-MM-DD')
                });

                this.shipments = shipments.map(s => this.$store.getters['shipments/getById'](s));
            },

            async fetchOrderStats() {
                let startDate = this.$moment().subtract(6, 'days');

                this.stats = await this.$api.get('/api/orders_stats', {
                    date: {
                        gte: startDate.toISOString()
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-card {
        min-height: 100%;
    }
</style>
