<template>
    <v-card>
        <v-card-title>
            <span>Comentarios Recientes</span>

            <v-spacer />

            <v-btn icon @click="onRefreshSelected">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <template v-if="comments && comments.length > 0">
                <div v-for="comment in comments" v-bind:key="comment.id">
                    <v-hover v-slot="{ hover }">
                        <v-row :class="{ 'hover': hover }" class="mb-4" @click="onCommentSelected(comment)">
                            <v-col class="flex-grow-0 pt-4">
                                <v-chip label x-small-chip color="blue" text-color="white">
                                    {{ comment.order.id }}
                                </v-chip>
                            </v-col>

                            <v-col>
                                <v-row>
                                    <v-col class="pb-0">
                                        <span class="title">{{ comment.createdBy.name }}</span>
                                    </v-col>

                                    <v-col class="pb-0 text-end">
                                        <span class="ml-1 caption grey--text">{{ comment.createdAt | moment('from', 'now') }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="pt-0">
                                        <span class="grey--text text--darken-2 body-1">{{ comment.comment }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-hover>
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                comments: []
            };
        },

        props: {
        },

        computed: {

        },

        watch: {

        },

        timers: {
            refreshTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        mounted() {
            this.fetchRecentOrderComments();
        },

        methods: {
            refreshTimer() {
                this.fetchRecentOrderComments();
            },

            async fetchRecentOrderComments() {
                let result = await this.$api.get('/api/order_comments', { limit: 5 });
                this.comments = result.records;
            },

            onCommentSelected(comment) {
                this.$router.push({ name: 'orderView', params: { id: comment.order.id } });
            },

            onRefreshSelected() {
                this.fetchRecentOrderComments();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .hover {
        background-color: #eeeeee;
    }
</style>
