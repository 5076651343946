<template>
    <v-card :loading="isLoading">
        <v-card-title>
            <span class="title">Clientes nuevos</span>

            <v-spacer />

            <v-btn icon @click="onRefreshSelected">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <v-data-table
                hide-default-header
                hide-default-footer
                :headers="customersHeaders"
                :items="newCustomers"
            >
                <template v-slot:item.isWholesaler="{ item }">
                    <span v-if="item.isWholesaler">Mayorista</span>
                    <span v-else>Minorista</span>
                </template>

                <template v-slot:item.fullName="{ item }">
                    {{ item.fullName | capitalize }}
                </template>

                <template v-slot:item.actions="{item}">
                    <div class="text-end">
                        <v-btn icon @click.stop="onCustomerViewSelected(item)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                customers: [],
                customersHeaders: [
                    { text: 'Nombre', value: 'fullName' },
                    { text: 'Tipo', value: 'isWholesaler' },
                    { text: '', value: 'actions' }
                ]
            };
        },

        props: {
        },

        computed: {
            isLoading() {
                return this.$store.getters['customer/isLoading']();
            },

            newCustomers() {
                return this.customers;
            }
        },

        watch: {
        },

        mounted() {
            this.fetchCustomers();
        },

        methods: {
            async fetchCustomers() {
                let result = await this.$store.dispatch('customer/fetchAll', {
                    limit: 5
                });
                this.customers = result.ids.map(id => this.$store.getters['customer/getById'](id));
            },

            async onRefreshSelected() {
                this.fetchCustomers();
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
