<template>
    <v-card :loading="isLoading">
        <v-card-title>
            <span>Pedidos recientes</span>

            <v-spacer />

            <v-btn icon @click="onRefreshSelected">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <order-list-table
                hide-footer
                :orders="recentOrders"
                :headers="ordersHeaders"
                v-model="selectedOrder"
            ></order-list-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import OrderListTable from '@/components/Order/OrderListTable';

    export default {
        components: {
            OrderListTable
        },

        data() {
            return {
                selectedOrder: null,
                orders: [],
                ordersHeaders: [
                    { text: 'Pedido #', value: 'id', width: '140px' },
                    { text: 'Cliente', value: 'customer' },
                    { text: 'Importe', value: 'total' },
                    { text: 'Pago', value: 'payment', hide: 'smAndDown' },
                    { text: '', value: 'icons', hide: 'smAndDown' },
                    { text: '', value: 'actions' }
                ]
            };
        },

        computed: {
            recentOrders() {
                return this.orders;
            },

            isLoading() {
                return this.$store.getters['order/isLoading']();
            }
        },

        watch: {
            selectedOrder(val) {
                this.$emit('input', val);
            }
        },

        timers: {
            refreshTimer: {
                time: 30000,
                autostart: true,
                repeat: true
            }
        },

        mounted() {
            this.fetchOrders();
        },

        methods: {
            refreshTimer() {
                this.fetchOrders();
            },

            async fetchOrders() {
                let result = await this.$store.dispatch('order/fetchAll', {
                    offset: 0,
                    limit: 10,
                    sortBy: 'id',
                    sortDirection: 'desc'
                });

                this.orders = result.ids.map(id => this.$store.getters['order/getById'](id));
            },

            onCustomerViewSelected(customer) {
                this.$router.push({ name: 'customerView', params: { id: customer.id } });
            },

            onRefreshSelected() {
                this.fetchOrders();
            }
        }
    };
</script>

<style lang="scss" scoped>
    
</style>
