<template>
    <v-card color="#00355e">
        <v-card-title>
            <span class="white--text">Ventas</span>

            <v-spacer></v-spacer>

            <span class="white--text">Ultimos 7 dias</span>
        </v-card-title>
        <v-card-text class="white--text display-1">
            {{ total | toCurrency }}
        </v-card-text>
        <apexchart type="area" height="100" :options="options" :series="series"></apexchart>
    </v-card>
</template>

<script>
    export default {
        components: {},

        data() {
            return {
                options: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: true,
                            formatter: (a, b) => this.$moment(this.series[b.seriesIndex].data[b.dataPointIndex].x).format('dddd D')
                        },
                        y: {
                            formatter: (a) => this.$options.filters.toCurrency(a),
                            title: {
                                formatter: (seriesName) => ''
                            }
                        },
                        marker: {
                            show: false
                        }
                    },
                    yaxis: {
                        min: 0
                    }
                },
                series: []
            };
        },

        props: {
            stats: {
                type: Array,
                default: () => []
            }
        },

        computed: {
            total() {
                return this.stats.reduce((a, v) => {
                    return a + parseFloat(v.total);
                }, 0);
            }
        },

        watch: {
            stats(result) {
                // console.log("last sunday: " + moment().day(-7).format("dddd, MMMM Do YYYY"));
                // console.log('Week: ' + moment().isoWeek());

                let series = [];
                for(let i = 0; i < result.length; ++i) {
                    series.push({
                        x: result[i].date,
                        y: result[i].total
                    });
                }

                this.series = [{
                    data: series
                }];
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
